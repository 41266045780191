import {graphql, useStaticQuery} from 'gatsby'

import {SearchPagesQuery} from '@/graphql-types'
import {sort} from '@/utilities/sort'

export const usePages = () => {
    const data = useStaticQuery<SearchPagesQuery>(graphql`
        query SearchPages {
            pages: allSitePage {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            tabs
                            status
                            category
                            intro
                            section
                            tags
                        }
                    }
                }
            }
        }
    `)

    const pages = data.pages.nodes
        .filter((page) => !!page.context?.frontmatter)
        .map((page) => {
            const meta = page.context.frontmatter
            return {
                path: page.path,
                title: meta.title,
                status: meta.status,
                category: meta.category,
                intro: meta.intro,
                tabs: meta.tabs,
                section: meta.section,
                tags: meta.tags
            }
        })

    return pages.sort(sort((c) => c.title.toLowerCase()))
}
