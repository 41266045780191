import {Link as GastbyLink} from 'gatsby'
import styled from 'styled-components'

import {tokens, duration, focusRing} from '@pleo-io/telescope'

export const Link = styled(GastbyLink)`
    color: ${tokens.colorContentInteractive};

    &:visited {
        color: ${tokens.colorContentInteractive};
    }
`

export const Tabs = styled.ul`
    display: flex;
    flex-direction: row;
    height: ${tokens.spacing40};
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
`

export const Tab = styled.li<{$active: boolean}>`
    display: flex;

    ${Link} {
        ${focusRing('regular')};
        position: relative;
        margin-right: ${tokens.spacing32};
        font-weight: ${({$active}) =>
            $active ? tokens.fontWeightSemibold : tokens.fontWeightRegular};
        font-size: ${tokens.fontMedium};
        text-decoration: none;
        transition-timing-function: ease-in-out;
        transition-duration: ${duration.smooth};
        transition-property: color;
        color: ${({$active}) =>
            $active
                ? `${tokens.colorContentInteractive}`
                : `${tokens.colorContentInteractiveQuiet}`};

        &:hover {
            color: ${tokens.colorContentInteractiveHover};
        }

        &::after {
            position: absolute;
            bottom: -1px;
            left: 0;
            display: ${({$active}) => ($active ? 'block' : 'none')};
            width: 100%;
            height: 1px;
            background-color: ${tokens.colorBorderInteractiveSelected};
            content: '';
        }
    }
`
