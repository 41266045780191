import React from 'react'
import styled from 'styled-components'

import {Button, ButtonGroup, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, NewTab} from '@pleo-io/telescope-icons'

import useComponentImages from '../docs/use-components-images'

const PreviewImage = styled.div`
    margin-bottom: ${tokens.spacing24};
    padding: ${tokens.spacing24} ${tokens.spacing60};
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-radius: ${tokens.arc12};
`

const Actions = styled(ButtonGroup)`
    margin-bottom: ${tokens.spacing36};
`

const LabComponent = ({
    title,
    githubUrl,
    storybookUrl,
    figmaUrl
}: {
    title: string
    githubUrl?: string
    storybookUrl?: string
    figmaUrl?: string
}) => {
    const images = useComponentImages()
    const image = images[title.toLowerCase()]

    return (
        <Stack space={18}>
            <PreviewImage>
                {image ? <img alt="preview" src={image} /> : 'No image available...'}
            </PreviewImage>
            <Actions>
                <Button
                    disabled={!storybookUrl}
                    target="_blank"
                    IconRight={NewTab}
                    variant="primary"
                    href={storybookUrl}
                    tooltipProps={{
                        content: !storybookUrl ? 'This component does not have documentation' : null
                    }}
                >
                    Documentation
                </Button>
                <Button
                    disabled={!figmaUrl}
                    target="_blank"
                    IconRight={NewTab}
                    variant="secondary"
                    href={figmaUrl}
                    tooltipProps={{
                        content: !figmaUrl ? 'This component does not exist in Figma' : null
                    }}
                >
                    Figma
                </Button>
                <Button
                    disabled={!githubUrl}
                    target="_blank"
                    IconRight={NewTab}
                    variant="secondary"
                    href={githubUrl}
                    tooltipProps={{
                        content: !githubUrl ? 'This component does not exist in Code' : null
                    }}
                >
                    Code
                </Button>
            </Actions>
            <Text variant="xlarge-accent" weight="semibold">
                This is an emerging component
            </Text>
            <Text>
                The {title} currently lives within the{' '}
                <Link href="/about/about.growth/#local-systems">local system</Link> (Telescope Lab)
                of our web application. It may not be implemented and documented according to{' '}
                <Link href="/about/about.sharing">our standards</Link> for components and as such,
                it may be subject to change in the future.
            </Text>

            <Text variant="xlarge-accent" weight="semibold">
                Become a contribution hero!
            </Text>
            <Text>
                The {title} classifies as a design system component. So we'd love it if you could
                help us in getting this added to Telescope.
            </Text>
            <Link href="/contributing/contributing.overview" IconRight={ArrowRight}>
                Get started contributing
            </Link>
        </Stack>
    )
}

export default LabComponent
