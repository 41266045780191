import React, {FC} from 'react'
import slugify from 'slugify'

import * as s from './tabs.styles'

export const Tabs: FC<{
    currentTab: string
    tabs?: string[]
    path: string
}> = ({tabs, currentTab, path}) => {
    const pageTabs = tabs?.map((tab) => {
        const slugifiedTab = slugify(tab, {lower: true})
        const active = slugifiedTab === currentTab.split('.')[1]
        const href = `${path.split('.')[0]}.${slugifiedTab}`

        return (
            <s.Tab key={tab} $active={active}>
                <s.Link to={href}>{tab}</s.Link>
            </s.Tab>
        )
    })

    return <s.Tabs>{pageTabs}</s.Tabs>
}
