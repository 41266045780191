import {Link as GatsbyLink, navigate} from 'gatsby'
import React, {useState} from 'react'
import styled from 'styled-components'

import {Input, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import useFuse from '@/hooks/use-fuse'

import {usePages} from './use-pages'

import {minWidthTOC} from '../layout/global-styles'
import {navWidth} from '../navigation/navigation.styles'

type Page = {
    path: string
    title: string
    status?: string
    category?: string
    intro?: string
    section?: string
    tags: string[]
}

const SearchResultsWrapper = styled.div<{$isVisible: boolean}>`
    position: absolute;
    top: calc(100% + 8px);
    left: ${tokens.spacing28};
    width: calc(100% - 56px);
    height: 400px;
    overflow: scroll;
    background-color: ${tokens.colorBackgroundStaticLoudest};
    border-radius: ${tokens.spacing12};
    opacity: ${({$isVisible}) => ($isVisible ? 1 : 0)};
    transition: ${tokens.smooth};
    pointer-events: ${({$isVisible}) => ($isVisible ? 'initial' : 'none')};

    @media (min-width: ${minWidthTOC}) {
        position: fixed;
        top: 0;
        left: ${navWidth}px;
        width: calc(100% - ${navWidth}px);
        height: 100%;
        padding: ${tokens.spacing24} ${tokens.spacing24} ${tokens.spacing24} 0;
        border-radius: 0;
    }
`

const List = styled.ul`
    min-height: calc(100% - 48px);
    overflow: hidden;
    background-color: ${tokens.colorBackgroundStatic};
    border-bottom-right-radius: ${tokens.arc8};
    border-bottom-left-radius: ${tokens.arc8};
`

const ListItem = styled.li<{$hasFauxFocus: boolean}>`
    background-color: ${({$hasFauxFocus}) =>
        $hasFauxFocus ? tokens.colorBackgroundStaticLoud : tokens.colorBackgroundStatic};
    border-bottom: ${({$hasFauxFocus}) =>
        $hasFauxFocus
            ? `1px solid ${tokens.colorBorderStatic}`
            : `1px solid ${tokens.colorBorderStatic}`};
    transition: border-bottom ${tokens.smooth};
`

const ListLink = styled(GatsbyLink)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${tokens.spacing18} ${tokens.spacing24};
    color: ${tokens.colorContentInteractive} !important;
    text-decoration: none !important;

    &:hover {
        background-color: ${tokens.colorBackgroundStaticLoud};
    }

    @media (min-width: ${minWidthTOC}) {
        padding: ${tokens.spacing36} ${tokens.spacing48};
    }
`

const ListLinkText = styled.span``

const Intro = styled(Text).attrs({variant: 'large-accent'})`
    display: none;

    @media (min-width: ${minWidthTOC}) {
        display: block;
        max-width: 60ch;
    }
`

const NavigationHelpText = styled(Text).attrs({variant: 'small-subtle'})`
    display: none;
    padding: ${tokens.spacing12};
    background-color: ${tokens.colorBackgroundStatic};
    border-bottom: 1px solid ${tokens.colorBorderStatic};
    border-top-left-radius: ${tokens.arc8};
    border-top-right-radius: ${tokens.arc8};

    @media (min-width: ${minWidthTOC}) {
        display: block;
    }
`

const NoResultsWrapper = styled.div`
    padding: ${tokens.spacing36} ${tokens.spacing48};
`

const Tags = styled.div`
    display: none;
    margin-top: ${tokens.spacing12};
    text-transform: capitalize;

    @media (min-width: ${minWidthTOC}) {
        display: flex;
    }
`

const TagsLabel = styled(Text)`
    margin-right: ${tokens.spacing4};
`

const NoResults = ({term}: {term: string}) => (
    <List>
        <NoResultsWrapper>
            <ListLinkText>
                <Text variant="2xlarge-accent" weight="semibold">
                    No results for "{term}"
                </Text>
                <Intro>
                    Search tips: some search terms require exact match. Try typing the entire search
                    term, or use a different word or phrase.
                </Intro>
            </ListLinkText>
        </NoResultsWrapper>
    </List>
)

const SearchResults = ({
    pages,
    focusedItem,
    term
}: {
    pages: Page[]
    focusedItem: number
    term: string
}) => {
    if (!pages.length) {
        return <NoResults term={term} />
    }

    return (
        <List>
            {pages.map((page, index) => (
                <ListItem key={page.path} $hasFauxFocus={focusedItem === index}>
                    <ListLink to={page.path}>
                        <ListLinkText>
                            <Text variant="small-subtle">{page.section}</Text>
                            <Text variant="2xlarge-accent" weight="semibold">
                                {page.title}
                            </Text>
                            <Intro>{page.intro}</Intro>
                            {page.tags && (
                                <Tags>
                                    <TagsLabel variant="small-subtle" weight="bold">
                                        Tags:
                                    </TagsLabel>
                                    <Text variant="small-subtle">
                                        {page.tags.map((tag) => tag).join(', ')}
                                    </Text>
                                </Tags>
                            )}
                        </ListLinkText>
                        {focusedItem === index && <ArrowRight size={24} />}
                    </ListLink>
                </ListItem>
            ))}
        </List>
    )
}

export const Search = () => {
    const pages = usePages()

    const landingPages = pages.filter((page) => {
        if (page.tabs && page.tabs.length > 1) {
            return page.tabs[0].toLowerCase() === page.path.split('.')[1]
        }
        return true
    })

    const [focusedItem, setFocusedItem] = useState(0)
    const {result, search, term} = useFuse<Page>(landingPages, {
        keys: ['title', 'section', 'tags', 'category']
    })

    const onKeyDown = (key: string) => {
        if (key === 'Enter') {
            navigate(result[focusedItem].path)
        } else if (key === 'ArrowDown' && focusedItem !== result.length) {
            setFocusedItem(focusedItem + 1)
        } else if (key === 'ArrowUp' && focusedItem !== 0) {
            setFocusedItem(focusedItem - 1)
        } else if (key === 'Escape') {
            search('')
            setFocusedItem(0)
        } else {
            setFocusedItem(0)
        }
    }

    return (
        <>
            <Input
                name="global-search"
                placeholder="Search..."
                value={term}
                onChange={(e) => search(e.target.value)}
                onKeyDown={(e) => onKeyDown(e.key)}
            />
            <SearchResultsWrapper $isVisible={!!term}>
                <NavigationHelpText>
                    ↑↓ to navigate | Enter to select | Esc to dismiss
                </NavigationHelpText>
                <SearchResults pages={result} focusedItem={focusedItem} term={term} />
            </SearchResultsWrapper>
        </>
    )
}
